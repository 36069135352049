import React, { useEffect, useState } from "react";
import "../styles/main/events/events.scss";
import { connect } from "react-redux";
import {
  getEvent,
  updateEvent,
  deleteEvent,
} from "../state-management/actions/eventActions";
import { useUserAuth } from "../hooks/UserAuthContext";
import BookingForm from "../components/common/booking-form/BookingForm";
import { useNavigate } from "react-router-dom";
import EventsTable from "../components/main/events/EventsTable";
import CustomButton from "../components/form/CustomButton";

const Events = (props) => {
  const user = useUserAuth();
  const navigate = useNavigate();

  const [event, setEvent] = useState(false);

  const handleDelete = (id) => {
    props.deleteEvent(id);
  };

  const handleUpdate = (id, data) => {
    props.updateEvent(id, data);
  };

  useEffect(() => {
    if (user) {
      props.getEvent(user.email);
    } else {
      navigate("/");
    }
  }, [user]);

  return (
    <div className="section">
      <h2 className="section_heading">Events Page</h2>
      <EventsTable
        handleDelete={handleDelete}
        handleUpdate={handleUpdate}
        events={props.events}
      />
      <div className="container event_button">
        <CustomButton
          text={event ? "Cancel" : "Add Event"}
          onClick={() => setEvent(!event)}
          lightMode={true}
        />
      </div>
      {event && <BookingForm setEvent={setEvent} />}
    </div>
  );
};

const mapStateToProps = (state) => ({
  events: state.eventReducer.events,
});

const mapDispatchToProps = (dispatch) => ({
  getEvent: (email) => dispatch(getEvent(email)),
  updateEvent: (id, data) => dispatch(updateEvent(id, data)),
  deleteEvent: (id) => dispatch(deleteEvent(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Events);
