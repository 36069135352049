import React, { useRef, useState } from "react";
import "../../../styles/common/booking-form/booking-form.scss";
import CustomButton from "../../form/CustomButton";
import TextField from "../../form/TextField";
import Recaptcha from "react-google-invisible-recaptcha";
import { connect } from "react-redux";
import { addEvent } from "../../../state-management/actions/eventActions";

const BookingForm = (props) => {
  const eventOptions = [
    "Wedding",
    "Bar/BatMitsvah",
    "Engagement",
    "House Party",
    "Birthday",
    "Other",
  ];

  const formInitialState = {
    firstName: "",
    lastName: "",
    number: "",
    email: "",
    eventDate: "",
    eventType: "",
    startTime: "",
    endTime: "",
    reference: "",
    artists: [],
    tracks: [],
  };

  const [formControl, setFormControl] = useState(formInitialState);
  const [resolved, setResolved] = useState(false);

  const refCaptcha = useRef(null);

  const SITE_KEY = "6LcNZ8AhAAAAAD_zr6F7XpEdMak9jXCAmnVkuJAw";

  const onResolved = () => {
    setResolved(true);
    // onRestart()
  };

  const onRestart = () => {
    setFormControl(formInitialState);
    setResolved(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormControl((prev) => ({ ...prev, [name]: value }));
    refCaptcha.current.callbacks.execute();
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (resolved) {
      props.booking(formControl);
      onRestart();
      props.setEvent(false);
    }

    // addBookingData(formControl)
  };

  return (
    <>
      <h2 className="section_heading" id="booking-form">
        Booking Form
      </h2>
      <form className="booking_form" onSubmit={onSubmit}>
        <TextField
          label="First Name"
          placeholder="John"
          value={formControl.firstName}
          onChange={handleChange}
          name={"firstName"}
          type={"text"}
          required={true}
        />
        <TextField
          label="Last Name"
          placeholder="Doe"
          value={formControl.lastName}
          onChange={handleChange}
          name={"lastName"}
          type={"text"}
          required={true}
        />
        <TextField
          label="Telephone Number"
          placeholder="123-456-789"
          value={formControl.number}
          onChange={handleChange}
          name={"number"}
          type={"tel"}
          required={true}
        />
        <TextField
          label="Email"
          placeholder="abc@gmail.com"
          value={formControl.email}
          onChange={handleChange}
          name={"email"}
          type={"email"}
          required={true}
        />
        <TextField
          label="Event Type"
          value={formControl.eventType}
          onChange={handleChange}
          name={"eventType"}
          type={"dropdown"}
          options={eventOptions}
        />
        <TextField
          label="Event Date"
          value={formControl.eventDate}
          onChange={handleChange}
          name={"eventDate"}
          type={"date"}
          required={true}
        />
        <TextField
          label="Event Start Time"
          value={formControl.startTime}
          onChange={handleChange}
          name={"startTime"}
          type={"time"}
          required={true}
        />
        <TextField
          label="Event End Time"
          value={formControl.endTime}
          onChange={handleChange}
          name={"endTime"}
          type={"time"}
          required={true}
        />

        <TextField
          label="Referred By"
          placeholder="John"
          value={formControl.reference}
          onChange={handleChange}
          name={"reference"}
          type={"text"}
        />
        <div className="from_recaptcha">
          <Recaptcha
            ref={refCaptcha}
            sitekey={SITE_KEY}
            onResolved={onResolved}
          />
        </div>
        <CustomButton
          type="submit"
          text="Confirm"
          form={true}
          lightMode={true}
        />
      </form>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  booking: (data) => dispatch(addEvent(data)),
});

export default connect(null, mapDispatchToProps)(BookingForm);
