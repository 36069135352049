import './App.scss';
import './styles/simple-grid.scss'
import Navbar from './components/layout/Navbar';
import Home from './pages/Home';
import "swiper/css/bundle";
import { Routes, Route } from "react-router-dom";
import Footer from './components/layout/Footer';
import MusicPlayer from './components/common/music-player/MusicPlayer';
import About from './pages/About';
import Blog from './pages/Blog';
import Login from './pages/Login';
import Events from './pages/Events';
import Service from './pages/Service';
import ContactUs from './pages/ContactUs';
import { UserAuthContextProvider } from "./hooks/UserAuthContext";
import WalkList from './pages/WalkList';
import CandleLight from './pages/CandleLight';
import React, { useEffect } from 'react';
import { useLocation } from "react-router-dom";
import Event from './pages/Event';

const ScrollTop = (props) => {
  const { children } = props;

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return <React.Fragment>{children}</React.Fragment>;
};

function App() {
  return (
    <UserAuthContextProvider>
      <div className="App">
        <Navbar />
        <ScrollTop>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/blog/:id" element={<Blog />} />
            <Route path="/login" element={<Login />} />
            <Route path="/event" element={<Events />} />
            <Route path="/event/:id" element={<Event />} />
            <Route path="/service/:id" element={<Service />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/walklist/:id" element={<WalkList />} />
            <Route path="/candlelight/:id" element={<CandleLight />} />
          </Routes>
        </ScrollTop>
        <Footer />
        {/* <MusicPlayer /> */}
      </div>
    </UserAuthContextProvider>
  );
}

export default App;
