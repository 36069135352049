import { GET_BLOGS_DATA } from "../types/types";

const initialState = [];

const blogsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BLOGS_DATA:
      return action.payload
    default:
      return state;
  }
};
export default blogsReducer;
