import React, { useEffect, useState } from "react";
import trash from "../../../assets/icons/trash-2.svg";
import edit from "../../../assets/icons/edit.svg";
import check from "../../../assets/icons/check.svg";
import ban from "../../../assets/icons/slash.svg";
import { Draggable } from "react-beautiful-dnd";

const TableRow = (props) => {
  const [enable, setEnable] = useState(true);

  useEffect(() => {
    props.edit && setEnable(edit);
  }, [props.edit]);

  const rowData = () => {
    return (
      <>
        <td className="table_data">{props.payload.persons.join(", ")}</td>

        <td className="table_data">
          <span>{props.payload.relationship}</span>
        </td>

        <td className="table_data">
          {props.payload.songs &&
            props.payload.songs.map((item, index) => (
              <span key={index}>{item.title}</span>
            ))}
        </td>

        <td>
          {enable ? (
            <div className="event_action">
              <img
                src={edit}
                alt="edit-icon"
                className="event_icon"
                onClick={() => {
                  props.edit && setEnable(false);
                  props.edit && props.handleClick(props.payload);
                  props.handleEdit(false);
                }}
              />
              <img
                src={trash}
                alt="trash-icon"
                className="event_icon"
                onClick={() => {
                  props.handleDelete(props.payload);
                }}
              />
            </div>
          ) : (
            <div className="event_action">
              <img
                src={ban}
                alt="slash-icon"
                className="event_icon"
                onClick={() => {
                  setEnable(true);
                  props.handleEdit(true);
                  props.handleClick();
                }}
              />
            </div>
          )}
        </td>
      </>
    );
  };

  return (
    <>
      {props.walklist ? (
        <Draggable
          key={props.payload.persons.join(", ")}
          draggableId={props.payload.persons.join(", ")}
          index={props.index}
        >
          {(provided) => (
            <tr
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              {rowData()}
            </tr>
          )}
        </Draggable>
      ) : (
        <tr>{rowData()}</tr>
      )}
    </>
  );
};

export default TableRow;
