import React, { useRef, useState } from "react";
import CustomButton from "../components/form/CustomButton";
import Textarea from "../components/form/Textarea";
import TextField from "../components/form/TextField";
import Recaptcha from "react-google-invisible-recaptcha";

const ContactUs = () => {
  const formInitialState = {
    firstName: "",
    lastName: "",
    email: "",
    message: ""
  };
  const [formControl, setFormControl] = useState(formInitialState);

  const [resolved, setResolved] = useState(false);
  const refCaptcha = useRef(null);

  const SITE_KEY = "6LcNZ8AhAAAAAD_zr6F7XpEdMak9jXCAmnVkuJAw";

  const onResolved = () => {
    //send data
    setResolved(true);
    onRestart();
  };

  const onRestart = () => {
    formControl(formInitialState);
    setResolved(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormControl((prev) => ({ ...prev, [name]: value }));
    refCaptcha.current.callbacks.execute();
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    resolved && console.log(formControl);
  };

  return (
    <div className="page_section container">
      <h2 className="section_heading">Contact Us</h2>
      <form className="form_container" onSubmit={onSubmit}>
        <Recaptcha
          ref={refCaptcha}
          sitekey={SITE_KEY}
          onResolved={onResolved}
        />
        <TextField
          login={true}
          label="First Name"
          placeholder="John"
          value={formControl.firstName}
          onChange={handleChange}
          name={"firstName"}
          type={"text"}
          required={true}
        />
        <TextField
          login={true}
          label="Last Name"
          placeholder="Doe"
          value={formControl.lastName}
          onChange={handleChange}
          name={"lastName"}
          type={"text"}
          required={true}
        />
        <TextField
          login={true}
          label="Email"
          placeholder="abc@gmail.com"
          value={formControl.email}
          onChange={handleChange}
          name={"email"}
          type={"email"}
          required={true}
        />
        <Textarea
          label="Message"
          placeholder="Message"
          value={formControl.message}
          onChange={handleChange}
          name={"message"}
          required={true}
        />
        <CustomButton type="submit" text="Send" form={true} lightMode={true} />
      </form>
    </div>
  );
};

export default ContactUs;
