import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../form/CustomButton";
import TableRow from "./TableRow";
import { ref, uploadBytes } from "firebase/storage";
import { storage } from "../../../firebase/firebase";
import TextField from "../../form/TextField";

const EventsTable = (props) => {
  const { events, handleUpdate, handleDelete } = props;

  const [edit, setEdit] = useState(true);
  const [eventId, setEventId] = useState("");
  const navigate = useNavigate();

  const handleEdit = (value, id) => {
    setEdit(value);
    setEventId(id);
  };

  const [imageAsFile, setImageAsFile] = useState([]);

  const getImage = (e) => {
    const image = Array.from(e.target.files);
    setImageAsFile(image);
  };

  const handleImage = () => {
    if (imageAsFile.length) {
      imageAsFile.map((item) => {
        const storageRef = ref(
          storage,
          `eventImages/${eventId}/${item.name}`
        );

        uploadBytes(storageRef, item).then((snapshot) => {
          console.log("Uploaded a blob or file!", snapshot);
        });
      });
    } else {
      console.error(`not an image, the image file is a ${typeof imageAsFile}`);
    }
  };

  return (
    <div className="container">
      <div className="table_container">
        <table>
          <thead>
            <tr>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Telphone</th>
              <th>Email</th>
              <th>Event Type</th>
              <th>Event Date</th>
              <th>Start Time</th>
              <th>End Time</th>
              <th>Refered by</th>
              <th>Manage</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {events?.map((item) => (
              <TableRow
                payload={item}
                key={item.id}
                handleUpdate={handleUpdate}
                handleDelete={handleDelete}
                handleEdit={handleEdit}
                handleImage={handleImage}
                edit={edit}
              />
            ))}
          </tbody>
        </table>
      </div>
      {!edit && (
        <div className="event_table_btn">
          <CustomButton
            text={"Request Song"}
            lightMode={true}
            onClick={() => {
              navigate(`${eventId}`);
              alert("Your song request was Successfully Sent.");
            }}
          />
          <input
            className="image_btn"
            type="file"
            name="image"
            multiple="multiple"
            accept="image/*"
            onChange={getImage}
          />
        </div>
      )}
    </div>
  );
};

export default EventsTable;
