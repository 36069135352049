import { addDoc, collection } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import { SET_NEWSLETTER } from "../types/types";


export const addNewsletter = (data) => async (dispatch) => {
  await addDoc(collection(db, "newsletter"), data);
  dispatch({
    type: SET_NEWSLETTER
  });
};