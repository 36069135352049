import React from 'react';
import BookingForm from '../components/common/booking-form/BookingForm';
import Header from '../components/layout/Header';
import AboutSection from '../components/main/home/AboutSection';
import BlogsSection from '../components/main/home/BlogsSection';
import ReviewSection from '../components/main/home/ReviewSection';
import ServiceSection from '../components/main/home/ServiceSection';
import WhyusSection from '../components/main/home/WhyusSection';

const Home = () => {
  return (
    <div>
      <Header />
      <ServiceSection />
      <WhyusSection />
      <AboutSection />
      {/* <BlogsSection /> */}
      {/* <ReviewSection /> */}
      <BookingForm />
    </div>
  );
}

export default Home;
