import React, { useEffect } from "react";
import { Link } from 'react-router-dom'
import { blogData } from "../../../helper/blogData";
import { useNavigate } from "react-router-dom";
import "../../../styles/main/home/blog-section.scss";
import { connect } from "react-redux";
import { getAllBlogs } from '../../../state-management/actions/blogs'

const BlogsSection = (props) => {
  
  useEffect(() => {
    props.getAllBlogs();
  }, []);

  return (
    <section className="section">
      <h2 className="section_heading">Blogs</h2>
      <div className="home_page_blog_container">
        {props.blogData?.map((item) => (
          <Link
            to={`/blog/${item.id}`}
            key={item.id}
            className="home_page_blog"
          >
            <img src={item.image} alt="blog-post" />
            <div className="home_page_blog_overlay">
              <div className="home_page_blog_content">
                <h4>{item.title}</h4>
                <p>{item.details}</p>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </section>
  );
};

const mapStateToProps = (state) => ({
  blogData: state.blogs
});

export default connect(mapStateToProps, { getAllBlogs })(BlogsSection);
