import React from "react";
import "../../styles/form/text-field.scss";
import plus from "../../assets/icons/plus.svg";

const TextField = (props) => {
  const {
    value,
    onChange,
    name,
    type,
    label,
    placeholder,
    required,
    login,
    options,
    disable,
    clip,
    addChip,
  } = props;

  return (
    <>
      {type === "dropdown" ? (
        <div className="text_field_container text_field_full_width">
          <label className="text_field_label" htmlFor={name}>
            {label}
          </label>
          <select
            className="text_field text_field_full_width"
            onChange={onChange}
            name={name}
            id={name}
          >
            {options.map((item) => (
              <option value={item} key={item}>
                {item}
              </option>
            ))}
          </select>
        </div>
      ) : (
        <div
          className={`${clip && "text_field_icon"} text_field_container ${login && "text_field_full_width"}`}
        >
          <label className="text_field_label" htmlFor={name}>
            {label}
          </label>
            <input
              className={"text_field"}
              value={value}
              onChange={onChange}
              name={name}
              type={type}
              placeholder={placeholder}
              id={name}
              required={required}
              disabled={disable}
            />
          {clip && <img src={plus} alt="plus-icon" onClick={addChip} />}
        </div>
      )}
    </>
  );
};

export default TextField;
