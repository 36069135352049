import React, { useEffect, useState } from "react";
import "../../styles/layout/navbar.scss";
import logo from "../../assets/logo/logo.svg";
import CustomButton from "../form/CustomButton";
import menu from "../../assets/icons/menu.svg";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import phone from "../../assets/icons/Icon awesome-ph.svg";
import facebook from "../../assets/icons/Icon awesome-fa.svg";
import twitter from "../../assets/icons/Icon awesome-tw.svg";
import youtube from "../../assets/icons/Icon awesome-yo.svg";
import insta from "../../assets/icons/Icon awesome-in.svg";
import { useUserAuth } from "../../hooks/UserAuthContext";
import { connect } from "react-redux";
import { signout } from "../../state-management/actions/authActions";

const Navbar = (props) => {

  const [toggleMenu, setToggleMenu] = useState(false);
  const [isScrollable, setIsScrollable] = useState(true);
  const user = useUserAuth();

  useEffect(() => {
    window.addEventListener("scroll", getWindowHeight);
  }, []);

  // disable scroll when menu is open
  if(toggleMenu){
    document.body.style.overflow = "hidden";
  }else{
    document.body.style.overflow = "visible";
  }

  const getWindowHeight = () => {
    const distanceY = window.pageYOffset || document.documentElement.scrollTop;
    const isScrollableOn = 10;
    //Now In the condition change the state to smaller so if the condition is true it will change to smaller otherwise to default state
    if (distanceY > isScrollableOn) {
      setIsScrollable(false);
    } else {
      setIsScrollable(true);
    }
  };

  let navigate = useNavigate();

  const handleLogin = () => {
    let path = "/login";
    navigate(path);
  };

   const handleLogout = () => {
     props.signout(user)
   };

  return (
    <>
      <div className="navbar_top">
        <div className="container">
          <div className="navbar_top_inner">
            <a href="tel:13102436339">
              <img src={phone} alt="phone-icon" />
              <span>13102436339</span>
            </a>
            <div className="navbar_social_icons">
              <a href="#">
                <img src={facebook} alt="facebook" />
              </a>
              <a href="#">
                <img src={twitter} alt="twitter" />
              </a>
              <a href="#">
                <img src={youtube} alt="youtube" />
              </a>
              <a href="#">
                <img src={insta} alt="instagram" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div
        className="navbar"
        style={{
          height: !isScrollable && "70px",
          paddingTop: !isScrollable && "5px",
          background: isScrollable ? "rgba(0,0,0,0)" : "#1a1917"
        }}
      >
        <div className="container">
          <div className="navbar_container">
            <Link to={'/'}>
              <img
                src={logo}
                alt="logo"
                className="navbar_logo"
                style={{ height: !isScrollable && "70px" }}
              />
            </Link>
            <div className="nav_menu_icon">
              {user ? (
                <CustomButton text="Sign out" onClick={handleLogout} />
              ) : (
                <CustomButton text="Register/Sign in" onClick={handleLogin} />
              )}

              <img
                src={menu}
                alt="hamburger-menu-icon"
                onClick={() => setToggleMenu(true)}
              />
            </div>
          </div>
          {toggleMenu && (
            <div className="nav_menu">
              <div
                className="nav_menu_close"
                onClick={() => setToggleMenu(false)}
              >
                &times;
              </div>
              <Link
                to="/"
                className="nav_link"
                onClick={() => setToggleMenu(false)}
              >
                Home
              </Link>
              <Link
                to="/"
                className="nav_link"
                onClick={() => setToggleMenu(false)}
              >
                Wedding DJs
              </Link>
              <Link
                to="/"
                className="nav_link"
                onClick={() => setToggleMenu(false)}
              >
                DJ Services
              </Link>
              <Link
                to="/event"
                className="nav_link"
                onClick={() => setToggleMenu(false)}
              >
              events
                {/* Blog */}
              </Link>
              <Link
                to="/about"
                className="nav_link"
                onClick={() => setToggleMenu(false)}
              >
                About Us
              </Link>
              <Link
                to="/contact-us"
                className="nav_link"
                onClick={() => setToggleMenu(false)}
              >
                Contact Us
              </Link>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  signout: (user) => dispatch(signout(user))
});

export default connect(null, mapDispatchToProps)(Navbar)