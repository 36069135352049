import axios from "axios";
import React, { createContext, useContext, useState } from "react";
import "../../../styles/common/request-song/request-song.scss";
import Tracks from "./Tracks";

const tracksContext = createContext();

const RequestSong = (props) => {
  const [search, setSearch] = useState("");
  const [tracks, setTracks] = useState([]);

  const { getSong, getYoutubeId, youtubeId, sendRequest } = props;

  const API_KEY = "AIzaSyCnvV_tr6JNdBBEv4flxss1IjkcSxUJbiY";

  const handleChange = (e) => {
    const { value } = e.target;
    setSearch(value);
  };

  const searchTracks = async (e) => {
    e.preventDefault();
    if (search !== "") {
      const { data } = await axios.get(
        "https://www.googleapis.com/youtube/v3/search",
        {
          params: {
            part: "snippet",
            q: search,
            key: API_KEY,
            maxResults: 15,
          },
        }
      );
      setTracks(data.items);
    } else {
      setTracks([]);
    }
  };

  return (
    <>
      <tracksContext.Provider
        value={{
          handleChange,
          searchTracks,
          tracks,
          getYoutubeId, 
          youtubeId,
          sendRequest,
          getSong
        }}
      >
        <Tracks />
      </tracksContext.Provider>
    </>
  );
};

export function useTracks() {
  return useContext(tracksContext);
}

export default RequestSong;
