import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCZnyvrqcc8P4MWk7RH1Vn1FrbeiEVWDgU",
  authDomain: "ohentgroup.firebaseapp.com",
  projectId: "ohentgroup",
  storageBucket: "ohentgroup.appspot.com",
  messagingSenderId: "456699251405",
  appId: "1:456699251405:web:456edae00c750b9f90b784",
  measurementId: "G-61ELW35RK6"
};

const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);
