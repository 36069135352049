import {
  addDoc,
  arrayRemove,
  arrayUnion,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where
} from "firebase/firestore";
import { db } from "../../firebase/firebase";
import {
  DELETE_EVENT,
  GET_ALL_TRACKS_FROM_RECOMMEND,
  GET_EVENT_BY_ID,
  GET_EVENT_DATA,
  REMOVE_ARTIST_DATA,
  REMOVE_ITEM_FROM_CANDLELIST,
  REMOVE_ITEM_FROM_WALKLIST,
  REMOVE_TRACK_DATA,
  SAVE_ARTIST_DATA,
  SAVE_EVENT_DATA,
  SAVE_ITEM_TO_CANDLELIST,
  SAVE_ITEM_TO_WALKLIST,
  SAVE_TRACK_DATA,
  SAVE_TRACK_TO_RECOMMEND,
  UPDATE_EVENT,
  UPDATE_ITEM_OF_CANDLELIST,
  UPDATE_ITEM_OF_WALKLIST,
  GET_WALKLIST_SUCCESS,
  GET_WALKLIST_FALIURE,
  UPDATE_WALKLIST
} from "../types/types";

export const addEvent = (data) => async (dispatch) => {
  const docRef = await addDoc(collection(db, "booking data"), data);
  dispatch({
    type: SAVE_EVENT_DATA,
    payload: { id: docRef.id, ...data }
  });
};

export const getEvent = (email) => async (dispatch) => {
  const q = query(collection(db, "booking data"), where("email", "==", email));
  const eventData = await getDocs(q);
  if (eventData.docs.length > 0) {
    const events = [];
    for (var snap of eventData.docs) {
      events.push({ id: snap.id, ...snap.data() });
    }
    dispatch({
      type: GET_EVENT_DATA,
      payload: events
    });
  }
};

export const getEventById = (id) => async (dispatch) => {
  const docRef = doc(db, "booking data", id);
  const docSnap = await getDoc(docRef);
  const data = docSnap.data()
  if (docSnap.exists()) {
    dispatch({
      type: GET_EVENT_BY_ID,
      payload: { id, ...data }
    });
  } else {
    console.log("No such document!");
  }
}

export const updateEvent = (id, data) => async (dispatch) => {
  const docRef = doc(db, "booking data", id);
  await updateDoc(docRef, {
    ...data
  });
  dispatch({
    type: UPDATE_EVENT,
    payload: { id, ...data }
  });
};

export const deleteEvent = (id) => async (dispatch) => {
  await deleteDoc(doc(db, "booking data", id));
  dispatch({
    type: DELETE_EVENT,
    payload: id
  });
};

export const addArtist = (id, data) => async (dispatch) => {
  const docRef = doc(db, "booking data", id);
  await updateDoc(docRef, {
    artists: arrayUnion(data)
  });
  dispatch({
    type: SAVE_ARTIST_DATA,
    payload: { id, artists: data }
  });
};

export const removeArtist = (id, data) => async (dispatch) => {
  const docRef = doc(db, "booking data", id);
  await updateDoc(docRef, {
    artists: arrayRemove(data)
  });
  dispatch({
    type: REMOVE_ARTIST_DATA,
    payload: { id, artists: data }
  });
};

export const addTrack = (id, data) => async (dispatch) => {
  const docRef = doc(db, "booking data", id);
  console.log(data)
  await updateDoc(docRef, {
    tracks: arrayUnion(data)
  });
  dispatch({
    type: SAVE_TRACK_DATA,
    payload: { id, track: data }
  });
};

export const removeTrack = (id, data) => async (dispatch) => {
  const docRef = doc(db, "booking data", id);
  await updateDoc(docRef, {
    tracks: arrayRemove(data)
  });
  dispatch({
    type: REMOVE_TRACK_DATA,
    payload: { id, track: data }
  });
};

export const addWalklist = (id, data) => async (dispatch) => {
  const docRef = doc(db, "booking data", id);
  await updateDoc(docRef, {
    walklist: arrayUnion(data)
  });
  dispatch({
    type: SAVE_ITEM_TO_WALKLIST,
    payload: { id, walklist: data }
  });
};

export const updateItemOfWalklist = (id, prev, data) => async (dispatch) => {
  const docRef = doc(db, "booking data", id);
  await updateDoc(docRef, {
    walklist: arrayRemove(prev),
  });
  await updateDoc(docRef, {
    walklist: arrayUnion(data),
  });
  dispatch({
    type: UPDATE_ITEM_OF_WALKLIST,
    payload: { id, prev: prev, data: data },
  });
};

export const updateWalklist = (id, data) => async (dispatch) => {
  const docRef = doc(db, "booking data", id);
  await updateDoc(docRef, {
    walklist: [],
  });
  await updateDoc(docRef, {
    walklist: data,
  });
  dispatch({
    type: UPDATE_WALKLIST,
    payload: { id, data: data },
  });
};


export const removeWalklist = (id, data) => async (dispatch) => {
  const docRef = doc(db, "booking data", id);
  await updateDoc(docRef, {
    walklist: arrayRemove(data)
  });
  dispatch({
    type: REMOVE_ITEM_FROM_WALKLIST,
    payload: { id, walklist: data }
  });
};

export const getWalklist = (id) => async (dispatch) => {
  try {
    const docRef = doc(db, "booking data", id);
    const docSnap = await getDoc(docRef);
    const data = docSnap.data()
    dispatch({
      type: GET_WALKLIST_SUCCESS,
      payload: { id, data }
    });
  } catch (error) {
    console.log('ERROR', error)
    dispatch({
      type: GET_WALKLIST_FALIURE,
      payload: []
    });
  }
};

export const addCandlelist = (id, data) => async (dispatch) => {
  const docRef = doc(db, "booking data", id);
  await updateDoc(docRef, {
    candlelist: arrayUnion(data)
  });
  dispatch({
    type: SAVE_ITEM_TO_CANDLELIST,
    payload: { id, candlelist: data }
  });
};

export const updateCandlelist = (id, prev, data) => async (dispatch) => {
  const docRef = doc(db, "booking data", id);
  await updateDoc(docRef, {
    candlelist: arrayRemove(prev)
  });
  await updateDoc(docRef, {
    candlelist: arrayUnion(data)
  });
  dispatch({
    type: UPDATE_ITEM_OF_CANDLELIST,
    payload: { id, prev: prev, data: data }
  });
};

export const removeCandlelist = (id, data) => async (dispatch) => {
  const docRef = doc(db, "booking data", id);
  await updateDoc(docRef, {
    candlelist: arrayRemove(data)
  });
  dispatch({
    type: REMOVE_ITEM_FROM_CANDLELIST,
    payload: { id, candlelist: data }
  });
};

export const addRecommendTrack = (data) => async (dispatch) => {
  const docRef = doc(db, "recommended tracks", "tracks");
  await updateDoc(docRef, {
    tracks: arrayUnion(data)
  });
  dispatch({
    type: SAVE_TRACK_TO_RECOMMEND,
    payload: { track: data }
  });
};

export const getAllRecommendedTracks = () => async (dispatch) => {
  const docRef = doc(db, "recommended tracks", "tracks");
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    dispatch({
      type: GET_ALL_TRACKS_FROM_RECOMMEND,
      payload: { ...docSnap.data() }
    });
  } else {
    console.log("No such document!");
  }
};