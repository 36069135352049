import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signInWithEmailAndPassword,
  signOut
} from "firebase/auth";
import { addDoc, collection } from "firebase/firestore";
import { auth, db } from "../../firebase/firebase";
import {
  SIGN_IN_ERROR,
  SIGN_IN_SUCCESS,
  SIGN_OUT_SUCCESS,
  SIGN_UP_ERROR,
  SIGN_UP_SUCCESS
} from "../types/types";

export const createUser = (data) => async (dispatch) => {
  const { firstName, lastName, email, password } = data;
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    await addDoc(collection(db, "users"), {
      uid: user.uid,
      firstName,
      lastName,
      email,
      timeStamp: new Date()
    });
    await sendEmailVerification(res.user).then(() => {
      alert("Verfication email sent");
    });
    dispatch({ type: SIGN_UP_SUCCESS });
  } catch (err) {
    dispatch({ type: SIGN_UP_ERROR, err });
  }
};

export const signin = (data) => async (dispatch) => {
  const { email, password } = data;
  try {
    await signInWithEmailAndPassword(auth, email, password);
    dispatch({ type: SIGN_IN_SUCCESS });
  } catch (err) {
    dispatch({ type: SIGN_IN_ERROR, err });
  }
};

export const signout = () => async (dispatch) => {
  signOut(auth);
  dispatch({ type: SIGN_OUT_SUCCESS });
};
