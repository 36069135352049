import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import { DateTime } from 'luxon'

import {
  addTrack,
  getEventById,
} from "../state-management/actions/eventActions";
import RequestSong from "../components/common/tracks/RequestTracks";
import "../styles/main/event/event.scss";

const Event = (props) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [youtubeId, setYoutubeId] = useState("");

  useEffect(() => {
    props.getEvent(id);
  }, []);

  const getYoutubeId = (id) => {
    setYoutubeId(id);
  };

  const saveData = async (track) => {
    if (track) {
      try {
        await axios.post(
          " https://us-central1-ohentgroup.cloudfunctions.net/notifySlack",
          {
            track,
          }
        );
        props.addTrack(id, track);
        navigate("/event");
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <div className="page_section container">
      {props.event && (
        <> <img
        src={props.event.eventPicture}
        className="event_image"
      />
          <h3 className="event-page-section-heading">{props.event.eventName}</h3>
          {props.event.eventDate &&
            <h2 className="section_heading">{DateTime.fromISO(props.event.eventDate).toLocaleString(DateTime.DATE_MED)}</h2>
          }
        </>
      )}
      
      <h3 className="event-page-section-sub-heading">Please Search for Your Song Below To Request A Song</h3>
      <RequestSong
        getYoutubeId={getYoutubeId}
        youtubeId={youtubeId}
        sendRequest={saveData}
      />
      {youtubeId !== "" && (
        <iframe
          src={`https://www.youtube.com/embed/${youtubeId}?autoplay=1`}
          width="560"
          height="315"
          frameBorder="0"
          allow="autoplay; encrypted-media"
          allowFullScreen
          title="video"
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  event: state.eventReducer.event,
});

const mapDispatchToProps = (dispatch) => ({
  getEvent: (id) => dispatch(getEventById(id)),
  addTrack: (id, data) => dispatch(addTrack(id, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Event);
