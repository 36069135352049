import React from "react";
import "../../../styles/main/home/why-us-section.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper";
import CustomButton from "../../form/CustomButton";
import { useNavigate } from "react-router-dom";
import { ohentData } from "../../../helper/ohentData";

const WhyusSection = () => {
  const navigate = useNavigate();

  return (
    <section className="section">
      <h2 className="section_heading">Why Oh Entertainment?</h2>
      <div className="why_us_section">
        <div className="why_us_content">
          <p>
            Oh! Entertainment prides itself on delivering quality sounds,
            lighting and video for your event regardless of the type. We spend
            quality time with the Event owner to make sure that all services are
            top quality and we are delivering the best results.
          </p>
          <div className="why_us_btn_container">
            <a href="#booking-form">
              <CustomButton text="Book Now" />
            </a>
            <CustomButton
              text="Contact Us"
              onClick={() => navigate("/contact-us")}
            />
          </div>
        </div>
        <div className="why_us_swiper_container">
          <Swiper
            slidesPerView={"auto"}
            spaceBetween={30}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination]}
            className="mySwiper why_us_swiper"
          >
            {ohentData.map((item) => (
              <SwiperSlide key={item.id}>
                <div className="service_section_carousel_card">
                  <img
                    src={item.image}
                    alt="service-card"
                    className="service_section_card_img"
                  />
                  <h3>{item.content}</h3>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default WhyusSection;
