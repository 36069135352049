import React from 'react';
import '../../styles/form/custom-button.scss'

const CustomButton = (props) => {
  const {text, lightMode, type, onClick, form} = props

  return (
    <button className={`button ${lightMode && 'button_light'} ${form && 'form_button'}`} onClick={onClick} type={type} >
      {text}
    </button>
  );
}

export default CustomButton;
