import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { serviceData } from '../helper/serviceData';
import { useUserAuth } from '../hooks/UserAuthContext';
import '../styles/main/service/service.scss'


const Service = () => {

    const { id } = useParams();
    const [service, setService] = useState({});

    const user = useUserAuth();
    const navigate = useNavigate();
    

    useEffect(() => {
      if (!user) navigate("/");
      serviceData.filter((data) => data.id === id && setService(data));
    }, [user, id]);

    return (
      <div className="section container service_page_container">
        <img src={service.image} alt="service" className="service_page_image" />
        <h2>{service.content}</h2>
      </div>
    );
}

export default Service;
