import React from "react";
import "../../../styles/common/table/table-input.scss";

const TableInput = (props) => {
  const { value, disabled, onChange, name, type, options } = props;
  return (
    <td>
      {type === "dropdown" ? (
        <select
          className="event_input event_dropdown"
          onChange={onChange}
          name={name}
          disabled={disabled}
          value={value}
        >
          {!disabled ? (
            options.map((item) => (
              <option value={item} key={item}>
                {item}
              </option>
            ))
          ) : (
            <option value={value}>{value}</option>
          )}
        </select>
      ) : (
        <input
          className={"event_input"}
          value={value}
          disabled={disabled}
          onChange={onChange}
          name={name}
          type={type}
        />
      )}
    </td>
  );
};

export default TableInput;
