import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {BrowserRouter} from 'react-router-dom'
import store from './state-management/store';
import { Provider } from "react-redux";
import { StyledEngineProvider } from "@mui/material/styles";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      {/* <React.StrictMode> */}
        <StyledEngineProvider injectFirst>
          <App />
        </StyledEngineProvider>
      {/* </React.StrictMode> */}
    </BrowserRouter>
  </Provider>
);
