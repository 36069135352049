import React, { useEffect, useState } from "react";
import "../styles/main/blogs/blog.scss";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { getAllBlogs } from "../state-management/actions/blogs";

const Blog = (props) => {
  const { id } = useParams();
  const [blog, setBlog] = useState({});

  useEffect(() => {
    props.getAllBlogs();
    props.blogData?.filter((data) => data.id === id && setBlog(data));
  }, [id]);

  return (
    <div className="section container blog_page_container">
      <img src={blog.image} alt="blog" className="blog_page_image" />
      <h2 className="blog_heading">{blog.title}</h2>
      <p className="blog_details">{blog.details}</p>
    </div>
  );
};

const mapStateToProps = (state) => ({
  blogData: state.blogs
});

export default connect(mapStateToProps, {getAllBlogs})(Blog);
