import React from 'react';
import '../../../styles/common/table/table-button.scss'

const TableButton = (props) => {
    const { text, onClick, disabled } = props;
    return (
      <td>
        <button
          className={"table_button"}
          disabled={disabled}
          onClick={onClick}
          style={{cursor: disabled ? 'not-allowed' : "pointer"}}
        >
          {text}
        </button>
      </td>
    );
}

export default TableButton;
