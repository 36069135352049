import React, { useEffect } from "react";
import Signin from "../components/main/login/Signin";
import Signup from "../components/main/login/Signup";
import "../styles/main/login/login-page.scss";
import { useNavigate } from "react-router-dom";
import { useUserAuth } from "../hooks/UserAuthContext";

const Login = () => {

  const user = useUserAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      user.emailVerified ? navigate("/events") : navigate("/");
    }
  }, [user, navigate]);


  return (
    <div className="container page_section">
      <div className="login_page_container">
        <Signup />
        <Signin />
      </div>
    </div>
  );
};


export default Login;
