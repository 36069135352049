import React from "react";
import '../../styles/layout/header.scss'
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { carouselData } from "../../helper/headerCarouselData";
import CustomButton from "../form/CustomButton";

const Header = () => {
    return (
      <div className="header">
        <Swiper
          pagination={{
            clickable: true,
          }}
          loop={true}
          spaceBetween={1}
          modules={[Pagination]}
        >
          {carouselData.map((item) => (
            <SwiperSlide key={item.id} className="header_swiper">
              <img
                src={item.image}
                alt="carousel-img"
                className="header_image"
              />
              <div className="header_overlay">
                <div className="container">
                  <div className="header_content_container">
                    <h1>{item.title}</h1>
                    <p>{item.content}</p>
                    <a href="#booking-form">
                      <CustomButton text="Book Now" />
                    </a>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    );
}

export default Header;
