import React from "react";
import "../../styles/layout/footer.scss";
import phone from "../../assets/icons/Icon awesome-ph.svg";
import map from "../../assets/icons/Icon awesome-ma.svg";
import facebook from "../../assets/icons/Icon awesome-fa.svg";
import twitter from "../../assets/icons/Icon awesome-tw.svg";
import youtube from "../../assets/icons/Icon awesome-yo.svg";
import insta from "../../assets/icons/Icon awesome-in.svg";
import { useState } from "react";
import { connect } from "react-redux";
import { addNewsletter } from "../../state-management/actions/newsletter";

const Footer = (props) => {

  const formInitialState = {
    email: ""
  };

  const [formControl, setFormControl] = useState(formInitialState)
  
  const handleChange = (e) => {
    const {name, value} = e.target;
    setFormControl((prev) => ({ ...prev, [name]: value }));
  }

  const onSubmit = (e) => {
    e.preventDefault();
    props.sendEmail(formControl)
    setFormControl(formInitialState)
  }

  const data = [
    {
      id: 1,
      icon: phone,
      content: <p>310-243-6339</p>
    },
    {
      id: 2,
      icon: map,
      content: (
        <p>
          10801 National Blvd. Suite # 576 <br />
          Los Angeles, CA 90064
        </p>
      )
    }
  ];

  return (
    <>
      <footer className="footer">
        <div className="container">
          <div className="footer_inner">
            <div className="footer_left_col">
              {data.map((item) => (
                <div key={item.id}>
                  <img src={item.icon} alt="icon" />
                  {item.content}
                </div>
              ))}
            </div>
            <div className="footer_right_col">
              <div className="footer_newsletter">
                <p>Newsletter</p>
                <form onSubmit={onSubmit}>
                  <input
                    type="email"
                    placeholder="Enter email"
                    name="email"
                    value={formControl.email}
                    required
                    onChange={handleChange}
                  />
                  <button type="submit">Sign up</button>
                </form>
              </div>
              <div className="footer_social_icons">
                <a href="#">
                  <img src={facebook} alt="facebook" />
                </a>
                <a href="#">
                  <img src={twitter} alt="twitter" />
                </a>
                <a href="#">
                  <img src={youtube} alt="youtube" />
                </a>
                <a href="#">
                  <img src={insta} alt="instagram" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="footer_copyright">
        <p>All Rights Reserved. Oh! Entertainment</p>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  sendEmail: (email) => dispatch(addNewsletter(email)),
});

export default connect(null, mapDispatchToProps)(Footer);
