import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  addWalklist,
  getAllRecommendedTracks,
  getEventById,
  removeWalklist,
  updateItemOfWalklist,
  updateWalklist
} from "../state-management/actions/eventActions";
import { connect } from "react-redux";
import { useState } from "react";
import CustomDropdown from "../components/form/CustomDropdown";
import RequestSong from "../components/common/tracks/RequestTracks";
import EventList from "../components/common/event-controls/EventList";
import EventForm from "../components/common/event-controls/EventForm";
import { addCandlelist, getWalklist } from './../state-management/actions/eventActions'

const WalkList = (props) => {
  const formInitialState = {
    name: "",
    relationship: "",
    song: {
      id: "",
      title: "",
    }
  };

  const [persons, setPersons] = useState([]);
  const [songs, setSongs] = useState([]);
  const [formControl, setFormControl] = useState(formInitialState);
  const [edit, setEdit] = useState(true);
  const [payload, setPayload] = useState([]);
  const [youtubeId, setYoutubeId] = useState("");
  const { id } = useParams();

  useEffect(() => {
    props.getEvent(id);
    props.getAllRecommendedTracks();
    props.getWalklist(id)
  }, [id]);

  const getYoutubeId = (id) => {
    setYoutubeId(id);
  };

  const getSong = (data) => {
    let exist = songs.filter((item) => item.id === data.id);
    !exist.length && setSongs((prev) => [...prev, data]);
  };

  const handleEdit = (value) => {
    setEdit(value);
  };

  const handleClick = (payload) => {
    setPayload(payload);
    // payload ? console.log(payload) : console.log("reset");
    payload ? updatePayload(payload) : onReset();
  };

  const updatePayload = (payload) => {
    setPersons(payload.persons);
    setSongs(payload.songs);
    setFormControl((prev) => ({ ...prev, relationship: payload.relationship }));
  };

  const handleUpdate = (data) => {
    props.updateItemOfWalklist(id, payload, data);
    setEdit(true);
  };

  const handleDelete = (data) => {
    props.removeItemFromWalklist(id, data);
  };

  const onReset = () => {
    setFormControl(formInitialState);
    setPersons([]);
    setSongs([]);
  };

  const handleRecommend = (data) => {
    let exist = songs.filter((item) => item.id === data.id);
    !exist.length && setSongs((prev) => [...prev, data]);
  };

  const updateList = (data) => {
    props.updateWalklist(id, data)
  }

  return (
    <div className="page_section container">
      <h3 className="section_heading">Walk List</h3>
      <section className="event_list_section">
        <main className="event_list_main">
          {props.event !== undefined && (
            <>
              <EventList
                edit={edit}
                updateList={updateList}
                walklist={true}
                handleClick={handleClick}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                handleUpdate={handleUpdate}
                list={props.event.walklist}
              />
            </>
          )}
          <EventForm
            edit={edit}
            handleUpdate={handleUpdate}
            addItemToList={props.addItemToCandlelist}
            setSongs={setSongs}
            songs={songs}
            persons={persons}
            setPersons={setPersons}
            id={id}
            formControl={formControl}
            setFormControl={setFormControl}
            onReset={onReset}
          />
        </main>
        <CustomDropdown
          label="Recommended Songs"
          options={props.recommendedTracks}
          handleRecommend={handleRecommend}
          getYoutubeId={getYoutubeId}
        />
      </section>
      {youtubeId !== "" && (
        <iframe
          src={`https://www.youtube.com/embed/${youtubeId}?autoplay=1`}
          className="event_list_iframe"
          frameBorder="0"
          allow="autoplay; encrypted-media"
          allowFullScreen
          title="video"
        />
      )}
      <RequestSong tracksOnly={true} getSong={getSong} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  event: state.eventReducer.event,
  recommendedTracks: state.eventReducer.tracks
});

const mapDispatchToProps = (dispatch) => ({
  getEvent: (id) => dispatch(getEventById(id)),
  addItemToWalklist: (id, data) => dispatch(addWalklist(id, data)),
  updateItemOfWalklist: (id, prev, data) =>
    dispatch(updateItemOfWalklist(id, prev, data)),
  updateWalklist: (id, data) =>
    dispatch(updateWalklist(id, data)),
  removeItemFromWalklist: (id, data) => dispatch(removeWalklist(id, data)),
  getAllRecommendedTracks: () => dispatch(getAllRecommendedTracks()),
  addItemToCandlelist: (id, data) => dispatch(addCandlelist(id, data)),
  getWalklist: (id) => dispatch(getWalklist(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WalkList);
