import React from "react";
import "../../../styles/main/home/service-section.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { serviceData } from "../../../helper/serviceData";
import { Link } from "react-router-dom";

const ServiceSection = () => {
  return (
    <section className="service_section">
      <h2 className="section_heading">Our Services</h2>
      <div className="service_section_carousel_container">
        <Swiper
          slidesPerView={"auto"}
          spaceBetween={30}
          centeredSlides={true}
          pagination={{
            clickable: true
          }}
          className="mySwiper service_section_swiper"
        >
          {serviceData.map((item) => (
            <SwiperSlide key={item.id}>
              <Link to={`/service/${item.id}`} className="home_page_service">
                <div className="service_section_carousel_card">
                  <img
                    src={item.image}
                    alt="service-card"
                    className="service_section_card_img"
                  />
                  <h3>{item.content}</h3>
                </div>
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

export default ServiceSection;
