import React, { useEffect, useState } from "react";
import "../../../styles/common/table/table-row.scss";
import trash from "../../../assets/icons/trash-2.svg";
import edit from "../../../assets/icons/edit.svg";
import check from "../../../assets/icons/check.svg";
import ban from "../../../assets/icons/slash.svg";
import TableInput from "../../common/table/TableInput";
import TableButton from "../../common/table/TableButton";
import { useNavigate } from "react-router-dom";

const TableRow = (props) => {
  const [enable, setEnable] = useState(true);
  const navigate = useNavigate();

  const initialState = {
    firstName: props.payload.firstName,
    lastName: props.payload.lastName,
    number: props.payload.number,
    email: props.payload.email,
    eventDate: props.payload.eventDate,
    eventType: props.payload.eventType,
    startTime: props.payload.startTime,
    endTime: props.payload.endTime,
    reference: props.payload.reference
  };

  const [formControl, setFormControl] = useState(initialState);
  const [currentDate, setCurrentDate] = useState('');

  const eventOptions = [
    "Wedding",
    "Bar/BatMitsvah",
    "Engagement",
    "House Party",
    "Birthday",
    "Other"
  ];

  useEffect(() => {
    setCurrentDate(new Date().toLocaleDateString('fr-CA'))
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormControl((prev) => ({ ...prev, [name]: value }));
  };

 const handleWalkList = () => {
  navigate(`/walklist/${props.payload.id}`);
 }

 const handleCandleList = () => {
  navigate(`/candlelight/${props.payload.id}`);
 }

  return (
    <tr>
      <TableInput
        value={formControl.firstName}
        disabled={enable}
        onChange={handleChange}
        name={"firstName"}
        type={"text"}
      />

      <TableInput
        value={formControl.lastName}
        disabled={enable}
        onChange={handleChange}
        name={"lastName"}
        type={"text"}
      />

      <TableInput
        value={formControl.number}
        disabled={enable}
        onChange={handleChange}
        name={"number"}
        type={"tel"}
      />

      <TableInput
        value={formControl.email}
        disabled={enable}
        onChange={handleChange}
        name={"email"}
        type={"email"}
      />

      <TableInput
        value={formControl.eventType}
        disabled={enable}
        onChange={handleChange}
        name={"eventType"}
        type={"dropdown"}
        options={eventOptions}
      />

      <TableInput
        value={formControl.eventDate}
        disabled={enable}
        onChange={handleChange}
        name={"eventDate"}
        type={"date"}
      />

      <TableInput
        value={formControl.startTime}
        disabled={enable}
        onChange={handleChange}
        name={"startTime"}
        type={"time"}
      />

      <TableInput
        value={formControl.endTime}
        disabled={enable}
        onChange={handleChange}
        name={"endTime"}
        type={"time"}
      />

      <TableInput
        value={formControl.reference}
        disabled={enable}
        onChange={handleChange}
        name={"reference"}
        type={"text"}
      />
      {formControl.eventType === "Wedding" ? (
        <TableButton
          text="walk list"
          disabled={props.payload.eventDate < currentDate}
          onClick={handleWalkList}
        />
      ) : formControl.eventType === "Bar/BatMitsvah" ? (
        <TableButton
          text="Candle list"
          disabled={props.payload.eventDate < currentDate}
          onClick={handleCandleList}
        />
      ) : (
        <td>coming soon</td>
      )}
      {/* <TableInput disabled={enable} onChange={props.getImage} type={"file"} /> */}
      <td>
        {enable ? (
          <div className="event_action">
            <img
              src={edit}
              alt="edit-icon"
              className="event_icon"
              onClick={() => {
                props.edit &&
                  props.payload.eventDate > currentDate &&
                  setEnable(false);
                props.payload.eventDate > currentDate &&
                  props.handleEdit(false, props.payload.id);
              }}
            />
            <img
              src={trash}
              alt="trash-icon"
              className="event_icon"
              onClick={() => {
                props.handleDelete(props.payload.id);
              }}
            />
          </div>
        ) : (
          <div className="event_action">
            <img
              src={check}
              alt="check-icon"
              className="event_icon"
              onClick={() => {
                setEnable(true);
                props.handleUpdate(props.payload.id, formControl);
                props.handleImage();
                props.handleEdit(true, '');
              }}
            />
            <img
              src={ban}
              alt="slash-icon"
              className="event_icon"
              onClick={() => {
                setEnable(true);
                setFormControl(initialState);
                props.handleEdit(true, '');
              }}
            />
          </div>
        )}
      </td>
    </tr>
  );
};

export default TableRow;
