import React, { useEffect } from "react";
import TableRow from "../table/TableRow";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { useState } from "react";

const EventList = (props) => {
  const { edit, handleClick, handleEdit, handleDelete, handleUpdate } = props;

  const [list, setList] = useState([]);

  useEffect(() => {
    setList(props.list);
  }, [props.list]);

  const handleOnDragEnd = (result) => {
    const items = Array.from(list);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setList(items);
    props.updateList(items)
  };

  return (
    <div className="table_container">
      <table>
        <thead>
          <tr>
            <th>Person</th>
            <th>Relationship</th>
            <th>Song</th>
            <th>Actions</th>
          </tr>
        </thead>
        {props.walklist ? (
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="walklist">
              {(provided) => (
                <tbody {...provided.droppableProps} ref={provided.innerRef}>
                  {list?.map((item, index) => (
                    <TableRow
                      walklist={true}
                      payload={item}
                      key={index}
                      index={index}
                      edit={edit}
                      handleClick={handleClick}
                      handleEdit={handleEdit}
                      handleDelete={handleDelete}
                      handleUpdate={handleUpdate}
                    />
                  ))}
                  {provided.placeholder}
                </tbody>
              )}
            </Droppable>
          </DragDropContext>
        ) : (
          <tbody>
            {list?.map((item, index) => (
              <TableRow
                payload={item}
                key={index}
                index={index}
                edit={edit}
                handleClick={handleClick}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                handleUpdate={handleUpdate}
              />
            ))}
          </tbody>
        )}
      </table>
    </div>
  );
};

export default EventList;
