import React, { useState, useEffect } from "react";
import "../../styles/form/custom-dropdown.scss";
import play from "../../assets/icons/play.svg";
import pause from "../../assets/icons/pause.svg";

const CustomDropdown = (props) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    props.options && props.options.length && setOptions(props.options);
  }, [props.options]);

  const { label, handleRecommend, getYoutubeId, youtubeId } = props;

  const handkeClick = (data) => {
    handleRecommend(data);
  };

  return (
    <aside className="recommend_list_container">
      <p className="recommend_list_title">{label}</p>
      {options.length &&
        options.map((item) => (
          <div className="recommend_list_content" key={item.id}>
            <p onClick={() => handkeClick(item)}>{item.title}</p>
            <img
              src={youtubeId === item.id ? pause : play}
              alt="play-icon"
              onClick={() => youtubeId === item.id ? getYoutubeId("") : getYoutubeId(item.id)}
            />
          </div>
        ))}
    </aside>
  );
};

export default CustomDropdown;
