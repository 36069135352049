import React from "react";
import CustomButton from "../../form/CustomButton";
import TextField from "../../form/TextField";
import { useTracks } from "./RequestTracks";
import SearchList from "./SearchList";

const Tracks = () => {
  const {
    tracks,
    searchTracks,
    handleChange,
    getSong,
    getYoutubeId,
    youtubeId,
    sendRequest,
  } = useTracks();

  const addItem = (data) => {
    getSong(data)
  };

  const youtube = (id) => {
    getYoutubeId(id)
  }

  const renderTracks = () => {
    return (
      <div className="track_container">
        <div className="track_inner_container">
          {tracks.map((item) => (
            <SearchList
              key={item.id.videoId}
              id={item.id.videoId}
              image={item.snippet.thumbnails.default.url}
              title={item.snippet.title}
              channel={item.snippet.channelTitle}
              addItem={addItem}
              external_url={`https://www.youtube.com/watch?v=${item.id.videoId}`}
              youtube={youtube}
              youtubeId={youtubeId}
              sendRequest={sendRequest}
            />
          ))}
        </div>
      </div>
    );
  };
  return (
    <div className="search_container">
      <form onSubmit={searchTracks} className="search">
        <TextField
          placeholder="Search Tracks"
          onChange={handleChange}
          name={"search"}
          type={"text"}
        />
        <CustomButton type="submit" text="search" lightMode={true} />
      </form>
      {renderTracks()}
    </div>
  );
};

export default Tracks;
