export const carouselData = [
  {
    id: 1,
    image: require("../assets/images/header/header1.jpg"),
    title: "DJ Omeed",
    content:
      "25 Years of experience and a vast collection of Persian, American and International music for your events."
  },
  {
    id: 2,
    image: require("../assets/images/header/header-bg.png"),
    title: "The DJ/live Fusion Band",
    content:
      "Top quality equipment used by our Djs to make sure the event is always memorable"
  }
];
