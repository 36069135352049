import React from 'react';

const About = () => {
    return (
        <div>
            Hello
        </div>
    );
}

export default About;
