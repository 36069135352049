import React from "react";
import "../../styles/form/textarea.scss";

const Textarea = (props) => {
  const { value, onChange, name, label, placeholder, required } = props;
  return (
    <div className="textarea_container">
      <label className="textarea_label" htmlFor={name}>
        {label}
      </label>
      <textarea
        className={"textarea"}
        value={value}
        onChange={onChange}
        name={name}
        placeholder={placeholder}
        id={name}
        required={required}
      />
    </div>
  );
};

export default Textarea;
