import {
  DELETE_EVENT,
  GET_ALL_TRACKS_FROM_RECOMMEND,
  GET_EVENT_BY_ID,
  GET_EVENT_DATA,
  REMOVE_ARTIST_DATA,
  REMOVE_ITEM_FROM_CANDLELIST,
  REMOVE_ITEM_FROM_WALKLIST,
  REMOVE_TRACK_DATA,
  SAVE_ARTIST_DATA,
  SAVE_EVENT_DATA,
  SAVE_ITEM_TO_CANDLELIST,
  SAVE_ITEM_TO_WALKLIST,
  SAVE_TRACK_DATA,
  SAVE_TRACK_TO_RECOMMEND,
  UPDATE_EVENT,
  UPDATE_ITEM_OF_CANDLELIST,
  UPDATE_ITEM_OF_WALKLIST,
  GET_WALKLIST_SUCCESS,
  UPDATE_WALKLIST
} from "../types/types";

import _ from "lodash";

const initialState = {
  events: [],
  event: {},
};

const eventReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_EVENT_DATA:
      return { events: action.payload };

    case GET_EVENT_BY_ID:
      return { event: action.payload };

    case SAVE_EVENT_DATA:
      return {
        ...state,
        events: [...state.events, action.payload],
      };

    case UPDATE_EVENT:
      state.events = state.events.map((item) => {
        if (item.id === action.payload.id) {
          return {
            ...item,
            ...action.payload,
          };
        } else {
          return item;
        }
      });
      return {
        ...state,
      };
    case DELETE_EVENT:
      state.events = state.events.filter(
        (item) => item.id !== action.payload.id
      );
      return {
        ...state,
      };

    case SAVE_ARTIST_DATA:
      state.events = state.events.map((item) => {
        if (item.id === action.payload.id) {
          const newArtist = action.payload.artists;
          let prevArtists = [...item.artists];
          item.artists = [newArtist, ...prevArtists];
          return {
            ...item,
          };
        } else {
          return item;
        }
      });
      return {
        ...state,
      };
    case REMOVE_ARTIST_DATA:
      state.events = state.events.map((item) => {
        if (item.id === action.payload.id) {
          const removeArtist = action.payload.artists;
          let prevArtists = [...item.artists];
          item.artists = prevArtists.filter(
            (item) => item.id !== removeArtist.id
          );
          return {
            ...item,
          };
        } else {
          return item;
        }
      });
      return {
        ...state,
      };
    case SAVE_TRACK_DATA:
      state.events = state.events.map((item) => {
        if (item.id === action.payload.id) {
          const newTrack = action.payload.track;
          let prevTracks = [...item.tracks];
          item.tracks = [newTrack, ...prevTracks];
          return {
            ...item,
          };
        } else {
          return item;
        }
      });
      return {
        ...state,
      };

    case REMOVE_TRACK_DATA:
      state.events = state.events.map((item) => {
        if (item.id === action.payload.id) {
          const removeTrack = action.payload.track;
          let prevTracks = item.tracks.filter(
            (item) => item.id !== removeTrack.id
          );
          item.tracks = prevTracks;

          return {
            ...item,
          };
        } else {
          return item;
        }
      });
      return {
        ...state,
      };

    case SAVE_ITEM_TO_WALKLIST:
      if (state.event.walklist !== undefined) {
        state.event.walklist.length >= 1
          ? (state.event.walklist = [
              ...state.event.walklist,
              action.payload.walklist,
            ])
          : (state.event.walklist = [action.payload.walklist]);
      } else {
        state.event.walklist = [action.payload.walklist];
      }

      return {
        ...state,
        event: { ...state.event },
      };

    case UPDATE_WALKLIST:
      state.event.walklist = action.payload.data;
      return {
        ...state,
        event: { ...state.event },
      };

    case UPDATE_ITEM_OF_WALKLIST:
      if (state.event.walklist !== undefined) {
        state.event.walklist = state.event.walklist.filter(
          (items) => !_.isEqual(items, action.payload.prev)
        );
      }
      state.event.walklist = [...state.event.walklist, action.payload.data];
      return {
        ...state,
        event: { ...state.event },
      };

    case REMOVE_ITEM_FROM_WALKLIST:
      if (state.event.walklist !== undefined) {
        state.event.walklist = state.event.walklist.filter(
          (item) => !_.isEqual(item, action.payload.walklist)
        );
      }
      return {
        ...state,
        event: { ...state.event },
      };

    case SAVE_ITEM_TO_CANDLELIST:
      if (state.event.candlelist !== undefined) {
        state.event.candlelist.length >= 1
          ? (state.event.candlelist = [
              ...state.event.candlelist,
              action.payload.candlelist,
            ])
          : (state.event.candlelist = [action.payload.candlelist]);
      } else {
        state.event.candlelist = [action.payload.candlelist];
      }
      return {
        ...state,
        event: { ...state.event },
      };

    case UPDATE_ITEM_OF_CANDLELIST:
      if (state.event.candlelist !== undefined) {
        state.event.candlelist = state.event.candlelist.filter(
          (items) => !_.isEqual(items, action.payload.prev)
        );
      }
      state.event.candlelist = [...state.event.candlelist, action.payload.data];
      return {
        ...state,
        event: { ...state.event },
      };

    case REMOVE_ITEM_FROM_CANDLELIST:
      if (state.event.candlelist !== undefined) {
        state.event.candlelist = state.event.candlelist.filter(
          (item) => !_.isEqual(item, action.payload.candlelist)
        );
      }
      return {
        ...state,
        event: { ...state.event },
      };

    case SAVE_TRACK_TO_RECOMMEND:
      state.tracks.length >= 1
        ? (state.tracks = [...state.tracks, action.payload.track])
        : (state.tracks = [action.payload.track]);
      return {
        ...state,
        tracks: { ...state.tracks },
      };
    case GET_ALL_TRACKS_FROM_RECOMMEND:
      return { ...state, tracks: action.payload.tracks };
    case GET_WALKLIST_SUCCESS:
      return { ...state, walklist: action.payload.data };
    default:
      return state;
  }
};
export default eventReducer;
