export const blogData = [
  {
    id: '1',
    title: "Lorem Ipsum dolor sit amet",
    details:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Error non eaque laudantium officiis sunt",
    image: require("../assets/images/blogs/Path-6117.png")
  },
  {
    id: '2',
    title: "Lorem Ipsum",
    details:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Error non eaque laudantium officiis sunt",
    image: require("../assets/images/blogs/Path-6118.png")
  },
  {
    id: '3',
    title: "Lorem Ipsum",
    details:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Error non eaque laudantium officiis sunt",
    image: require("../assets/images/blogs/Path-6119.png")
  },
  {
    id: '4',
    title: "Lorem Ipsum",
    details:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Error non eaque laudantium officiis sunt",
    image: require("../assets/images/blogs/Path-6120.png")
  }
];