import React, { useState } from "react";
import CustomButton from "../../form/CustomButton";
import TextField from "../../form/TextField";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import _ from "lodash";
import '../../../styles/common/event-controls/event-form.scss'

const EventForm = (props) => {
  const {
    edit,
    handleUpdate,
    addItemToList,
    setSongs,
    songs,
    persons,
    setPersons,
    id,
    formControl,
    setFormControl,
    onReset
  } = props;

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      console.log('persons_songs', persons, songs)
      if (persons.length && songs.length) {
        let data = {
          persons,
          songs,
          relationship: formControl.relationship,
        };
        edit ? addItemToList(id, data) : handleUpdate(data);
        onReset();
      } else {
        alert("songs or persons can't be empty");
      }
    } catch (error) {
      console.log('Error', error)
    }
  };

  const handleSong = (e) => {
    setFormControl((prev) => ({
      ...prev,
      song: {
        id: "",
        title: e.target.value,
      },
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormControl((prev) => ({ ...prev, [name]: value }));
  };

  const addPerson = () => {
    let exist = persons.filter(
      (item) => item.toLowerCase() === formControl.name.toLowerCase()
    );
    !exist.length &&
      formControl.name !== "" &&
      setPersons((prev) => [...prev, formControl.name]);
    setFormControl((prev) => ({ ...prev, name: "" }));
  };

  const addSong = () => {
    let exist = songs.filter(
      (item) =>
        item.title.toLowerCase() === formControl.song.title.toLowerCase()
    );
    !exist.length &&
      formControl.song.title !== "" &&
      setSongs((prev) => [...prev, formControl.song]);
    setFormControl((prev) => ({
      ...prev,
      song: {
        id: "",
        title: "",
      },
    }));
  };

  const deleteSong = (data) => {
    setSongs(songs.filter((item) => !_.isEqual(item, data)));
  };

  const deletePerson = (name) => {
    setPersons(persons.filter((item) => item !== name));
  };

  return (
    <form className="form_container" onSubmit={handleSubmit}>
      <TextField
        login={true}
        label="Person Name"
        placeholder="John"
        value={formControl.name}
        onChange={handleChange}
        name={"name"}
        type={"text"}
        clip={true}
        addChip={addPerson}
      />
      <Stack direction="row" spacing={1} className="chip_container">
        {persons &&
          persons.map((item, index) => (
            <Chip
              key={index}
              className="chip"
              label={item}
              onDelete={() => deletePerson(item)}
            />
          ))}
      </Stack>
      <TextField
        login={true}
        label="Relationship"
        placeholder="Siblings"
        value={formControl.relationship}
        onChange={handleChange}
        name={"relationship"}
        type={"text"}
        required={true}
      />
      <TextField
        login={true}
        label="Song"
        placeholder="song name"
        value={formControl.song.title}
        onChange={handleSong}
        name={"song"}
        type={"text"}
        clip={true}
        addChip={addSong}
      />
      <Stack direction="row" spacing={1} className="chip_container">
        {songs &&
          songs.map((item, index) => (
            <Chip
              key={index}
              className="chip"
              label={item.title}
              onDelete={() => deleteSong(item)}
            />
          ))}
      </Stack>
      <CustomButton type="submit" text="Save" form={true} lightMode={true} />
    </form>
  );
};

export default EventForm;
