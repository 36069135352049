export const serviceData = [
  {
    id: "1",
    content: "DJ/Live Band",
    image: require("../assets/images/service/dj_controller_how_to_800x.webp"),
  },
  {
    id: "2",
    content: "Lighting",
    image: require("../assets/images/service/Elegante-Entertainment-Events-Corporate-Party2.jpeg"),
  },
  {
    id: "3",
    content: "Audio/Video",
    image: require("../assets/images/service/ss-627708197-audio-visual-IT.webp"),
  },
];
