export const ohentData = [
  {
    id: 1,
    content: "Wedding",
    image: require("../assets/images/why-us/advanced-dance-floor-lighting-e1449635200351.png"),
  },
  {
    id: 2,
    content: "Bar/Bat Mitsvahs",
    image: require("../assets/images/why-us/IMG_0660.jpg"),
  },
  {
    id: 3,
    content: "Corporate Events",
    image: require("../assets/images/why-us/IMG_0661.jpg"),
  },
  {
    id: 4,
    content: "Private Parties",
    image: require("../assets/images/why-us/IMG_0662.jpg"),
  },
];