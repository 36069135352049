import React, { useState } from 'react';
import { connect } from 'react-redux';
import '../../../styles/main/login/signup.scss'
import CustomButton from '../../form/CustomButton';
import TextField from '../../form/TextField';
import { createUser } from "../../../state-management/actions/authActions";

const Signup = (props) => {
     const [formControl, setFormControl] = useState({
       firstName: "",
       lastName: "",
       email: "",
       password: ""
     });
     const handleChange = (e) => {
       const { name, value } = e.target;
       setFormControl((prev) => ({ ...prev, [name]: value }));
     };
     const onSubmit = async (e) => {
       e.preventDefault();
       props.signup(formControl);
     };
    return (
      <section className="signup_section">
        <h3 className="section_heading">Sign Up</h3>
        <form className="form_container" onSubmit={onSubmit}>
          <TextField
            login={true}
            label="First Name"
            placeholder="John"
            value={formControl.firstName}
            onChange={handleChange}
            name={"firstName"}
            type={"text"}
            required={true}
          />
          <TextField
            login={true}
            label="Last Name"
            placeholder="Doe"
            value={formControl.lastName}
            onChange={handleChange}
            name={"lastName"}
            type={"text"}
            required={true}
          />
          <TextField
            login={true}
            label="Email"
            placeholder="abc@gmail.com"
            value={formControl.email}
            onChange={handleChange}
            name={"email"}
            type={"email"}
            required={true}
          />
          <TextField
            login={true}
            label="Password"
            placeholder="Password"
            value={formControl.password}
            onChange={handleChange}
            name={"password"}
            type={"password"}
          />
          <CustomButton
            type="submit"
            text="Sign Up"
            form={true}
            lightMode={true}
          />
        </form>
      </section>
    );
}

const mapDispatchToProps = (dispatch) => ({
  signup: (data) => dispatch(createUser(data))
});

export default connect(null, mapDispatchToProps)(Signup);
