import React from 'react';
import '../../../styles/main/home/about-section.scss'

const AboutSection = () => {
    return (
      <section className="section container">
        <h2 className="section_heading">About Us</h2>
        <p className="about_section_content">
          Oh! Entertainment Group started in 1992 and offers a unique musical
          blend that packs a high-energy punch! The DJ/live fusion band, or
          DJ/live band hybrid, is always anchored on a DJ set but supported by
          live musicians. With all the energy and hype of a live band but the
          flexibility of a DJ, you won’t have to decide between hiring a band or
          a DJ for your next event.
        </p>
      </section>
    );
}

export default AboutSection;
