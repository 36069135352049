import { combineReducers } from "redux";
import blogs from "./blogs";
import eventReducer from "./eventReducer";
import auth from './authReducer'

export default combineReducers({
  blogs,
  eventReducer,
  auth,
});
