export const GET_BLOGS_DATA = "GET_BLOGS_DATA";

//booking event
export const GET_EVENT_DATA = "GET_EVENT_DATA";
export const SAVE_EVENT_DATA = "SAVE_EVENT_DATA";
export const UPDATE_EVENT = "UPDATE_EVENT_DATA";
export const DELETE_EVENT = "DELETE_EVENT";

// tracks and artists
export const SAVE_ARTIST_DATA = "SAVE_ARTIST_DATA";
export const REMOVE_ARTIST_DATA = "REMOVE_ARTIST_DATA";
export const SAVE_TRACK_DATA = "SAVE_TRACK_DATA";
export const REMOVE_TRACK_DATA = "REMOVE_TRACK_DATA";
export const GET_EVENT_BY_ID = "GET_EVENT_BY_ID";

// walk list
export const SAVE_ITEM_TO_WALKLIST = "SAVE_ITEM_TO_WALKLIST";
export const UPDATE_WALKLIST = "UPDATE_WALKLIST";
export const UPDATE_ITEM_OF_WALKLIST = "UPDATE_ITEM_OF_WALKLIST";
export const REMOVE_ITEM_FROM_WALKLIST = "REMOVE_ITEM_FROM_WALKLIST";
export const GET_WALKLIST_SUCCESS = "GET_WALKLIST_SUCCESS";
export const GET_WALKLIST_FALIURE = "GET_WALKLIST_FALIURE";

//candle list
export const SAVE_ITEM_TO_CANDLELIST = "SAVE_ITEM_TO_CANDLELIST";
export const UPDATE_ITEM_OF_CANDLELIST = "UPDATE_ITEM_OF_CANDLELIST";
export const REMOVE_ITEM_FROM_CANDLELIST = "REMOVE_ITEM_FROM_CANDLELIST";

//auth success and errors
export const SIGN_IN_ERROR = "SIGN_IN_ERROR";
export const SIGN_IN_SUCCESS = "SIGN_IN_SUCCESS";
export const SIGN_OUT_SUCCESS = "SIGN_OUT_SUCCESS";
export const SIGN_UP_SUCCESS = "SIGN_UP_SUCCESS";
export const SIGN_UP_ERROR = "SIGN_UP_ERROR";

//newsletter
export const SET_NEWSLETTER = "SET_NEWSLETTER";

// recommended list
export const SAVE_TRACK_TO_RECOMMEND = "SAVE_TRACK_TO_RECOMMEND";
export const GET_ALL_TRACKS_FROM_RECOMMEND = "GET_ALL_TRACKS_FROM_RECOMMEND";