import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import {
  addCandlelist,
  getAllRecommendedTracks,
  getEventById,
  removeCandlelist,
  updateCandlelist,
} from "../state-management/actions/eventActions";
import CustomDropdown from "../components/form/CustomDropdown";
import RequestSong from "../components/common/tracks/RequestTracks";
import EventForm from "../components/common/event-controls/EventForm";
import EventList from "../components/common/event-controls/EventList";

const CandleLight = (props) => {
  const formInitialState = {
    name: "",
    relationship: "",
    song: {
      id: "",
      title: "",
      external_url: ""
    },
  };

  const [formControl, setFormControl] = useState(formInitialState);
  const [persons, setPersons] = useState([]);
  const [songs, setSongs] = useState([]);
  const [edit, setEdit] = useState(true);
  const [payload, setPayload] = useState([]);
  const [youtubeId, setYoutubeId] = useState("");
  const { id } = useParams();

  const onReset = () => {
    setFormControl(formInitialState);
    setPersons([]);
    setSongs([]);
  };

  useEffect(() => {
    props.getEvent(id);
    props.getAllRecommendedTracks();
  }, []);

  const getYoutubeId = (id) => {
    setYoutubeId(id);
  };

  const getSong = (data) => {
    let exist = songs.filter((item) => item.id === data.id);
    !exist.length && setSongs((prev) => [...prev, data]);
  };

  const handleEdit = (value) => {
    setEdit(value);
  };

  const handleClick = (payload) => {
    setPayload(payload);
    payload ? updatePayload(payload) : onReset();
  };

  const updatePayload = (payload) => {
    setPersons(payload.persons);
    setSongs(payload.songs);
    setFormControl((prev) => ({ ...prev, relationship: payload.relationship }));
  };

  const handleUpdate = (data) => {
    props.updateItemOfCandlelist(id, payload, data);
    setEdit(true);
  };

  const handleDelete = (data) => {
    props.removeItemFromCandlelist(id, data);
  };

  const handleRecommend = (data) => {
    let exist = songs.filter((item) => item.id === data.id);
    !exist.length && setSongs((prev) => [...prev, data]);
  };

  return (
    <div className="page_section container">
      <h3 className="section_heading">Candle list</h3>
      <section className="event_list_section">
        <main className="event_list_main">
          {props.event !== undefined && (
            <EventList
              edit={edit}
              handleClick={handleClick}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
              handleUpdate={handleUpdate}
              list={props.event.candlelist}
            />
          )}
          <EventForm
            edit={edit}
            handleUpdate={handleUpdate}
            addItemToList={props.addItemToCandlelist}
            setSongs={setSongs}
            songs={songs}
            persons={persons}
            setPersons={setPersons}
            id={id}
            formControl={formControl}
            setFormControl={setFormControl}
            onReset={onReset}
          />
        </main>
        <CustomDropdown
          label="Recommended Songs"
          options={props.recommendedTracks}
          handleRecommend={handleRecommend}
          getYoutubeId={getYoutubeId}
          youtubeId={youtubeId}
        />
      </section>
      {youtubeId !== "" && (
        <iframe
          src={`https://www.youtube.com/embed/${youtubeId}?autoplay=1`}
          className="event_list_iframe"
          frameBorder="0"
          allow="autoplay; encrypted-media"
          allowFullScreen
          title="video"
        />
      )}
      <RequestSong
        getSong={getSong}
        getYoutubeId={getYoutubeId}
        youtubeId={youtubeId}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  event: state.eventReducer.event,
  recommendedTracks: state.eventReducer.tracks,
});

const mapDispatchToProps = (dispatch) => ({
  getEvent: (id) => dispatch(getEventById(id)),
  addItemToCandlelist: (id, data) => dispatch(addCandlelist(id, data)),
  updateItemOfCandlelist: (id, prev, data) =>
    dispatch(updateCandlelist(id, prev, data)),
  removeItemFromCandlelist: (id, data) => dispatch(removeCandlelist(id, data)),
  getAllRecommendedTracks: () => dispatch(getAllRecommendedTracks()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CandleLight);
