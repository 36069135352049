import React from "react";
import pause from "../../../assets/icons/pause.svg";
import play from "../../../assets/icons/play.svg";
import CustomButton from "../../form/CustomButton";

const TracksList = (props) => {
  const {
    id,
    image,
    title,
    channel,
    addItem,
    external_url,
    youtube,
    youtubeId,
    sendRequest,
  } = props;

  const handleClick = () => {
    addItem({ id, title, image, channel, external_url });
  };

  return (
    <div className="track">
      <div
        className="track_content_container"
        onClick={handleClick}
      >
        <img src={image} className="track_poster" alt="video-thumbnail" />

        <div className="track_content">
          <p>{title}</p>
          {channel && (
            <p>
              <span>{channel}</span>
            </p>
          )}
        </div>
      </div>
      <div className="track_icon_container">
        <img
          src={youtubeId === id ? pause : play}
          alt="play-icon"
          className="track_icon"
          onClick={() => (youtubeId === id ? youtube("") : youtube(id))}
        />
        {sendRequest && (
          <CustomButton
            text="Request song"
            lightMode={true}
            onClick={() => {
              alert("Your song request was Successfully Sent.");
              sendRequest({ id, title, channel, external_url })
            }}
             
          />
        )}
      </div>
    </div>
  );
};

export default TracksList;
